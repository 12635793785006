import React from 'react';
import './styles.css';

interface TeamMemberProps {
  img: string;
  name: string;
  text: string;
}

const TeamMember: React.FC<TeamMemberProps> = ({ img, name, text }) => {
  if(text !== undefined){
    return (
      <div className="team-member-container">
        <div className="row">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="d-flex align-items-center justify-content-center h-100 img-container">
              <img src={img} alt={`Foto ${name}`} />
            </div>
          </div>
          <div className="col-sm-12 col-md-8 col-lg-8">
            <div className="team-member-about-content">
              <h2>{name}</h2>
              {text.map((item,key) => <p key={key}>{item}</p>)}
            </div>
          </div>
        </div>
      </div>
    );
  }else{
    return "";
  }
}

export default TeamMember;
