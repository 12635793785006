import React from 'react';
import { motion } from 'framer-motion';
import TeamMember from './TeamMember';
import Divider from '../../components/Divider';
import alineImg from '../../assets/images/team/Aline.jpg';
import dorisImg from '../../assets/images/team/Doris.jpg';
import grazieleImg from '../../assets/images/team/Grazielle.jpg';

let alineTxt = [
  "Gestora de Projetos: Relações Externas, Financeiro, Monitoria e Avaliação.",
  "Graduada pela Universidade Federal de Minas Gerais e University of Electronic, Science and, Technology of China.",
  "Áreas: Design, Ciências Sociais, Gestão Pública.",
  "Atuação/Interesses: Gestão de Projetos, Angariação de Fundos, Políticas Públicas, Cidadania, Ações Afirmativas."
];

let dorisTxt = [
  "Gestora de Projetos: Comunicadora Interinstitucional, Maker, Educadora.",
  "Graduada pela Universidade Federal de Minas Gerais.",
  "Área: Saúde.",
  "Atuação/Interesses: Mulheres Negras, Relações Raciais, Encarceramento, Adolescência, Sistema Socioeducativo, Educação e Saúde, Doença Falciforme."
];

let grazieleTxt = [
  "Gestora de Projetos: Gestão Pedagógica e Educadora.",
  "Graduada pela Universidade Federal de Minas Gerais.",
  "Área: Educação.",
  "Atuação/Interesses: Gestão Pedagógica, Educação Popular, Descolonização e Educação, Adolescência, Sistema Socioeducativo."
];

const variants = {
  initial:{
    opacity:0,
    x:-20
  },
  animate:{
    opacity:1,
    x: 0,
    transition:{ 
      x:{ type: "spring", stiffness: 50},
      opacity: { duration: 1 }
    },
  }
}

const containerVariants = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {
          when: "beforeChildren",
          staggerChildren: 0.3,
        },
    }
};

const Team: React.FC = () => {
  return (
    <div id="page-team">
    <Divider text="NOSSA EQUIPE"/>
      <motion.div className="container" variants={containerVariants} initial="initial" animate="animate">
        <div className="row">
          <motion.div className="col-md-12" variants={variants} >
            <TeamMember 
              img={dorisImg} 
              name="Maria Doris Faustino" 
              text={dorisTxt} />
          </motion.div>
        </div>
        <div className="row">
          <motion.div className="col-md-12" variants={variants} >
              <TeamMember 
                img={grazieleImg} 
                name="Graziele Freitas Dias" 
                text={grazieleTxt} />
          </motion.div>
        </div>
        <div className="row">
          <motion.div className="col-md-12" variants={variants} >
              <TeamMember 
                img={alineImg} 
                name="Aline Izaias Lucio" 
                text={alineTxt} />
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}

export default Team;
